
.icon {
  display: inline-block;
  font-size: 16px;
}

@font-face {
  font-family: 'epic-icon';
  src:  url('epic-icon.woff2') format('woff2'),
        url('epic-icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.epic-icon {
	line-height: 1;
}

.epic-icon:before {
	font-family: epic-icon !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.epic-icon-nike-logo:before {
	content: "\f11b";
}

.epic-icon-search:before {
	content: "\f120";
}

.epic-icon-slack-with-name:before {
	content: "\f122";
}
