@font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('HelveticaNeueRegular.woff2') format('woff2'),
        url('HelveticaNeueRegular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('HelveticaNeueMedium.woff2') format('woff2'),
        url('HelveticaNeueMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

