@font-face {
  font-family: 'Futura';
  src: url(../assets/fonts/futura/FuturaNDforNike365Cn-XBd.woff2) format('woff2'),
      url(../assets/fonts/futura/FuturaNDforNike365Cn-XBd.woff) format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url(../assets/fonts/trade-gothic/TradeGothicforNike365-BdCn.woff2) format('woff2'),
      url(../assets/fonts/trade-gothic/TradeGothicforNike365-BdCn.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}


@font-face {
    font-family: 'Helvetica Neue Regular';
    src: url(../assets/fonts/helvetica-neue/HelveticaNeueRegular.woff2) format('woff2'),
        url(../assets/fonts/helvetica-neue/HelveticaNeueRegular.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue Medium';
    src: url(../assets/fonts/helvetica-neue/HelveticaNeueMedium.woff2) format('woff2'),
        url(../assets/fonts/helvetica-neue/HelveticaNeueMedium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}



.icon {
  display: inline-block;
  font-size: 16px;
}

@font-face {
  font-family: 'epic-icon';
  src:  url(../assets/fonts/epic-icon/epic-icon.woff2) format('woff2'),
        url(../assets/fonts/epic-icon/epic-icon.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

.epic-icon {
	line-height: 1;
}

.epic-icon:before {
	font-family: epic-icon !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.epic-icon-add:before {
	content: "\f101";
}
.epic-icon-arrow-backward:before {
	content: "\f102";
}
.epic-icon-arrow-down:before {
	content: "\f103";
}
.epic-icon-arrow-forward:before {
	content: "\f104";
}
.epic-icon-arrow-up:before {
	content: "\f105";
}
.epic-icon-bookmark:before {
	content: "\f106";
}
.epic-icon-calendar:before {
	content: "\f107";
}
.epic-icon-checkmark:before {
	content: "\f108";
}
.epic-icon-chevron-back:before {
	content: "\f109";
}
.epic-icon-chevron-circle-back:before {
	content: "\f10a";
}
.epic-icon-chevron-circle-forward:before {
	content: "\f10b";
}
.epic-icon-chevron-down:before {
	content: "\f10c";
}
.epic-icon-chevron-forward:before {
	content: "\f10d";
}
.epic-icon-chevron-up:before {
	content: "\f10e";
}
.epic-icon-close-x:before {
	content: "\f10f";
}
.epic-icon-collaborate:before {
	content: "\f110";
}
.epic-icon-confluence:before {
	content: "\f111";
}
.epic-icon-contact:before {
	content: "\f112";
}
.epic-icon-delete:before {
	content: "\f113";
}
.epic-icon-edit:before {
	content: "\f114";
}
.epic-icon-filter-x:before {
	content: "\f115";
}
.epic-icon-filter:before {
	content: "\f116";
}
.epic-icon-github:before {
	content: "\f117";
}
.epic-icon-information:before {
	content: "\f118";
}
.epic-icon-jira:before {
	content: "\f119";
}
.epic-icon-map-pin:before {
	content: "\f11a";
}
.epic-icon-nike-logo:before {
	content: "\f11b";
}
.epic-icon-preview-eye:before {
	content: "\f11c";
}
.epic-icon-preview:before {
	content: "\f11d";
}
.epic-icon-profile:before {
	content: "\f11e";
}
.epic-icon-quotes:before {
	content: "\f11f";
}
.epic-icon-search:before {
	content: "\f120";
}
.epic-icon-share:before {
	content: "\f121";
}
.epic-icon-slack-with-name:before {
	content: "\f122";
}
.epic-icon-slack:before {
	content: "\f123";
}
.epic-icon-steps:before {
	content: "\f124";
}
.epic-icon-up-and-running:before {
	content: "\f125";
}
.epic-icon-user-profile:before {
	content: "\f126";
}
.epic-icon-walkthrough:before {
	content: "\f127";
}
.epic-icon-warning:before {
	content: "\f128";
}

.epic-font-base {
  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
}
.epic-font-base-md {
  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;
}
.epic-font-marketing {
  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
}
.epic-font-brand {
  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
}
.epic-font-base {
  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
}
.epic-font-base-md {
  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;
}
.epic-font-marketing {
  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
  letter-spacing: -1px;
  text-transform: uppercase;
  line-height: 0.93;
  letter-spacing: -1px;
}
.epic-font-brand {
  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.epic-color-default {
  color: #5197d6;
}
.epic-color-accent {
  color: #fa5400;
}
.epic-color-success {
  color: #48bd1f;
}
.epic-color-error {
  color: #fe0000;
}
.epic-color-warn {
  color: #eeb450;
}
.epic-bg-default {
  background-color: #5197d6;
}
.epic-bg-accent {
  background-color: #fa5400;
}
.epic-bg-success {
  background-color: #48bd1f;
}
.epic-bg-danger {
  background-color: #fe0000;
}
.epic-bg-warn {
  background-color: #eeb450;
}
.epic-color-grey-1 {
  color: #f7f7f7;
}
.epic-color-grey-2 {
  color: #ececec;
}
.epic-color-grey-3 {
  color: #e5e5e5;
}
.epic-color-grey-4 {
  color: #c7c7c7;
}
.epic-color-grey-5 {
  color: #b2b2b4;
}
.epic-color-grey-6 {
  color: #8b8c8c;
}
.epic-color-grey-7 {
  color: #6d6d6d;
}
.epic-bg-grey-1 {
  background-color: #f7f7f7;
}
.epic-bg-grey-2 {
  background-color: #ececec;
}
.epic-bg-grey-3 {
  background-color: #e5e5e5;
}
.epic-bg-grey-4 {
  background-color: #c7c7c7;
}
.epic-bg-grey-5 {
  background-color: #b2b2b4;
}
.epic-bg-grey-6 {
  background-color: #8b8c8c;
}
.epic-bg-grey-7 {
  background-color: #6d6d6d;
}
body {
  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif !important;
  font-weight: normal;
}

